import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'

import Layout from '../layouts/home'
import SEO from '../components/seo'

export default function BlogsPage({ data }) {

  const renderBlogsList = () => {
    return data.allMarkdownRemark.edges.map(e => {
      return <div class="col-md-6">
        <div class="card" style={{ marginBottom: '30px' }}>
          <div class="card-body">
            <h5 class="card-title">{ e.node.frontmatter.title }</h5>
            <p class="card-text">{ e.node.frontmatter.summary }</p>
            <Link to={`/blogs${e.node.fields.slug}`}>Continue Reading →</Link>
          </div>
        </div>
      </div>
    })
  }

  return <Layout>
    <SEO
      title="Blogs"
      keywords={['weddings', 'events', 'tips', 'guides']}
      description="Weddings, Events and Nepalese Ceremonies related articles, tips and guides"
    />
    <section class="reserve-block light-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h5>Blogs</h5>
          </div>
        </div>
      </div>
    </section>
    <section class="reserve-block ">
      <div class="container">
        <div class="row">
          { renderBlogsList() }
        </div>
      </div>
    </section>
  </Layout>
}

export const query = graphql`
  query GetBlogs {
    allMarkdownRemark(filter: { fields: { collection: { eq: "blog" }}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            path
            title
            date(formatString: "MMMM DD, YYYY")
            summary
          }
        }
      }
    }
  }
`
